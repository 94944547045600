import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`Autogenerate Outlook signatures`}</h2>
    <p>{`This script will generate signatures in Outlook based on a template .docx. It will log all signature updates to a single server, so you can track rollout and updates`}</p>
    <p>{`To use this script, you must place the template .docx file in the same location as the script (can be run from a GPO). Microsoft Word must be installed on the target machine, which should be the case if Outlook is installed.`}</p>
    <p>{`Template replacement format is `}{`[`}{`Title`}{`]`}{` which will be replaced with the same value within Active Directory, eg. SharePoint Designer`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "powershell"
    }}><pre parentName="div" {...{
        "className": "language-powershell"
      }}><code parentName="pre" {...{
          "className": "language-powershell"
        }}><span parentName="code" {...{
            "className": "token comment"
          }}>{`#!/usr/local/bin/pwsh`}</span>{`
`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`<#
    .SYNOPSIS
    Script to set Outlook 2010/2013 e-mail signature using Active Directory information

    .DESCRIPTION
    This script will set the Outlook 2010/2013 e-mail signature on the local client using Active Directory information.
    The template is created with a Word document, where images can be inserted and AD values can be provided.

    Author: George Paton
    Version 2.1
#>`}</span>{`

`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`###  Global Vars ###`}</span>{`
`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`## Company Variables`}</span>{`
`}<span parentName="code" {...{
            "className": "token variable"
          }}>{`$COMPANY_LONG_NAME`}</span>{`  = `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"Upstream Production Solutions"`}</span>{`
`}<span parentName="code" {...{
            "className": "token variable"
          }}>{`$COMPANY_SHORT_NAME`}</span>{` = `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"Upstream PS"`}</span>{`
`}<span parentName="code" {...{
            "className": "token variable"
          }}>{`$COMPANY_INITIALS`}</span>{`   = `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"UPS"`}</span>{`
`}<span parentName="code" {...{
            "className": "token variable"
          }}>{`$COMPANY_VAR_NAME`}</span>{`   = `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"upstreamps"`}</span>{`
`}<span parentName="code" {...{
            "className": "token variable"
          }}>{`$COMPANY_EMAIL_EXT`}</span>{`  = `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"@UpstreamPS.com"`}</span>{`
`}<span parentName="code" {...{
            "className": "token variable"
          }}>{`$COMPANY_EMAIL_ENC`}</span>{`  = `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"_upstreamps_com"`}</span>{`

`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`### Logging Variables`}</span>{`
`}<span parentName="code" {...{
            "className": "token variable"
          }}>{`$LOG_FOLDER`}</span>{` = `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"\\\\Log-Server\\Log-Share\\Logs\\"`}</span>{`
`}<span parentName="code" {...{
            "className": "token variable"
          }}>{`$SIGNATURE_LOG_FOLDER`}</span>{` = `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"Set-Outlook-Signature\\"`}</span>{`

`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`## Signature Variables`}</span>{`
`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`# Signature name as it shows in Outlook`}</span>{`
`}<span parentName="code" {...{
            "className": "token variable"
          }}>{`$SIGNATURE_COMPOSE_NAME`}</span>{` = `}<span parentName="code" {...{
            "className": "token variable"
          }}>{`$COMPANY_VAR_NAME`}</span>{`
`}<span parentName="code" {...{
            "className": "token variable"
          }}>{`$SIGNATURE_REPLY_NAME`}</span>{`   = `}<span parentName="code" {...{
            "className": "token variable"
          }}>{`$COMPANY_VAR_NAME`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`+`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"-reply"`}</span>{`

`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`# Separate signatures for compose/reply emails`}</span>{`
`}<span parentName="code" {...{
            "className": "token variable"
          }}>{`$SIGNATURE_SEPARATE`}</span>{` = `}<span parentName="code" {...{
            "className": "token boolean"
          }}>{`$true`}</span>{`

`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`# Signature template filenames`}</span>{`
`}<span parentName="code" {...{
            "className": "token variable"
          }}>{`$SIGNATURE_COMPOSE_TEMPLATE`}</span>{` = `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"template-compose.docx"`}</span>{`
`}<span parentName="code" {...{
            "className": "token variable"
          }}>{`$SIGNATURE_REPLY_TEMPLATE`}</span>{`   = `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"template-reply.docx"`}</span>{`

`}<span parentName="code" {...{
            "className": "token variable"
          }}>{`$SIGNATURE_FORCE`}</span>{`         = `}<span parentName="code" {...{
            "className": "token boolean"
          }}>{`$false`}</span>{`  `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`#Set to $true if you don't want the users to be able to change signature in Outlook`}</span>{`
`}<span parentName="code" {...{
            "className": "token variable"
          }}>{`$SIGNATURE_FORCE_THEME`}</span>{`   = `}<span parentName="code" {...{
            "className": "token boolean"
          }}>{`$true`}</span>{`   `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`#Set to $true to force Outlook default message theme within registry - ONLY IN Office 2013`}</span>{`
`}<span parentName="code" {...{
            "className": "token variable"
          }}>{`$SIGNATURE_MARK_COMMENTS`}</span>{` = `}<span parentName="code" {...{
            "className": "token boolean"
          }}>{`$true`}</span>{`   `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`#Enable marking comments in emails with initials eg. [GP] - ONLY IN Office 2013`}</span>{`

`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`# Default email compose and reply styling`}</span>{`
`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`# Colour is in BGR format, not RGB`}</span>{`
`}<span parentName="code" {...{
            "className": "token variable"
          }}>{`$SIGNATURE_COMPOSE_FONT`}</span>{`  = `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"Calibri"`}</span>{`
`}<span parentName="code" {...{
            "className": "token variable"
          }}>{`$SIGNATURE_COMPOSE_SIZE`}</span>{`  = 11
`}<span parentName="code" {...{
            "className": "token variable"
          }}>{`$SIGNATURE_COMPOSE_COLOR`}</span>{` = 0x5c3405

`}<span parentName="code" {...{
            "className": "token variable"
          }}>{`$SIGNATURE_REPLY_FONT`}</span>{`    = `}<span parentName="code" {...{
            "className": "token variable"
          }}>{`$SIGNATURE_COMPOSE_FONT`}</span>{`
`}<span parentName="code" {...{
            "className": "token variable"
          }}>{`$SIGNATURE_REPLY_SIZE`}</span>{`    = `}<span parentName="code" {...{
            "className": "token variable"
          }}>{`$SIGNATURE_COMPOSE_SIZE`}</span>{`
`}<span parentName="code" {...{
            "className": "token variable"
          }}>{`$SIGNATURE_REPLY_COLOR`}</span>{`   = `}<span parentName="code" {...{
            "className": "token variable"
          }}>{`$SIGNATURE_COMPOSE_COLOR`}</span>{`

`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`# Maximum amount of days to elapse before autorenewing signature`}</span>{`
`}<span parentName="code" {...{
            "className": "token variable"
          }}>{`$SIGNATURE_MAX_AGE`}</span>{`       = 60

`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`# Specify which lines to remove from completed template to handle blank fields in AD`}</span>{`
`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`# This is an ARRAY LITERAL, please read:`}</span>{`
`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`# * http://blogs.msdn.com/b/powershell/archive/2007/01/23/array-literals-in-powershell.aspx`}</span>{`
`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`# ORDER MATTERS, Items higher up will be evaluated first`}</span>{`
`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`# ^? matches any character, in this case, a newline character (so if an entire line is removed,`}</span>{`
`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`# the newline is also removed, which ensures no weird blank lines show up)`}</span>{`
`}<span parentName="code" {...{
            "className": "token variable"
          }}>{`$SIGNATURE_TEMPLATE_BLANKS`}</span>{` =
    `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"a: [StreetAddress], [l] [st] [postalCode]^?"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
    `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"d: [TelephoneNumber] | r: [HomePhone] | m: [Mobile]^?"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
    `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"d: [TelephoneNumber] | r: [HomePhone] | "`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
    `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"| r: [HomePhone] | m: [Mobile]"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
    `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"| m: [Mobile]"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
    `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"d: [TelephoneNumber] | "`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
    `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"r: [HomePhone] | "`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
    `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"[l] "`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
    `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"[st] "`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
    `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"[postalCode]"`}</span>{`
`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`### /Global Vars ###`}</span>{`

`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`if`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`-not`}</span>{` `}<span parentName="code" {...{
            "className": "token variable"
          }}>{`$SIGNATURE_SEPARATE`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
    `}<span parentName="code" {...{
            "className": "token variable"
          }}>{`$SIGNATURE_REPLY_NAME`}</span>{` = `}<span parentName="code" {...{
            "className": "token variable"
          }}>{`$SIGNATURE_COMPOSE_NAME`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`

`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`# Stop execution on unhandled errors`}</span>{`
`}<span parentName="code" {...{
            "className": "token variable"
          }}>{`$ErrorActionPreference`}</span>{` = `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"Stop"`}</span>{`

`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`# Environment variables`}</span>{`
`}<span parentName="code" {...{
            "className": "token variable"
          }}>{`$appData`}</span>{` = `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`Get-Item`}</span>{` env:appdata`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`value
`}<span parentName="code" {...{
            "className": "token variable"
          }}>{`$signaturePath`}</span>{` = `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"\\Microsoft\\Signatures"`}</span>{`
`}<span parentName="code" {...{
            "className": "token variable"
          }}>{`$localSignaturePath`}</span>{` = `}<span parentName="code" {...{
            "className": "token variable"
          }}>{`$appData`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`+`}</span>{` `}<span parentName="code" {...{
            "className": "token variable"
          }}>{`$signaturePath`}</span>{`

`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`# Check signature path, and create if necessary`}</span>{`
`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`if`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`-not`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`Test-Path`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`-`}</span>{`Path `}<span parentName="code" {...{
            "className": "token variable"
          }}>{`$localSignaturePath`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
    `}<span parentName="code" {...{
            "className": "token function"
          }}>{`New-Item`}</span>{` `}<span parentName="code" {...{
            "className": "token variable"
          }}>{`$localSignaturePath`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`-`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`Type`}</span>{` Directory
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`

`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`# Logging`}</span>{`
`}<span parentName="code" {...{
            "className": "token variable"
          }}>{`$logPath`}</span>{` = `}<span parentName="code" {...{
            "className": "token variable"
          }}>{`$localSignaturePath`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`+`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"\\set_outlook_signature.log"`}</span>{`
`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`if`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`-not`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`Test-Path`}</span>{` `}<span parentName="code" {...{
            "className": "token variable"
          }}>{`$logPath`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`-or`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`Get-Item`}</span>{` `}<span parentName="code" {...{
            "className": "token variable"
          }}>{`$logPath`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`Length `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`-gt`}</span>{` 100kb`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
    `}<span parentName="code" {...{
            "className": "token function"
          }}>{`New-Item`}</span>{` `}<span parentName="code" {...{
            "className": "token variable"
          }}>{`$logPath`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`-`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`type`}</span>{` file `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`-`}</span>{`force
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`
`}<span parentName="code" {...{
            "className": "token function"
          }}>{`Start-Transcript`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`-`}</span>{`path `}<span parentName="code" {...{
            "className": "token variable"
          }}>{`$logPath`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`-`}</span>{`Append

`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`# Get Active Directory information for current user`}</span>{`
`}<span parentName="code" {...{
            "className": "token variable"
          }}>{`$username`}</span>{` = `}<span parentName="code" {...{
            "className": "token variable"
          }}>{`$env`}</span>{`:username
`}<span parentName="code" {...{
            "className": "token variable"
          }}>{`$filter`}</span>{` = `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"(&(objectCategory=User)(samAccountName=`}<span parentName="span" {...{
              "className": "token variable"
            }}>{`$username`}</span>{`))"`}</span>{`
`}<span parentName="code" {...{
            "className": "token variable"
          }}>{`$searcher`}</span>{` = `}<span parentName="code" {...{
            "className": "token function"
          }}>{`New-Object`}</span>{` System`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`DirectoryServices`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`DirectorySearcher
`}<span parentName="code" {...{
            "className": "token variable"
          }}>{`$searcher`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token keyword"
          }}>{`Filter`}</span>{` = `}<span parentName="code" {...{
            "className": "token variable"
          }}>{`$filter`}</span>{`
`}<span parentName="code" {...{
            "className": "token variable"
          }}>{`$ADUserPath`}</span>{` = `}<span parentName="code" {...{
            "className": "token variable"
          }}>{`$searcher`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`FindOne`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
`}<span parentName="code" {...{
            "className": "token variable"
          }}>{`$ADUser`}</span>{` = `}<span parentName="code" {...{
            "className": "token variable"
          }}>{`$ADUserPath`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`GetDirectoryEntry`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`

`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`### Timestamping - lets not run this more than we need to`}</span>{`
`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`# Get last run timestamp`}</span>{`
`}<span parentName="code" {...{
            "className": "token variable"
          }}>{`$timestampPath`}</span>{` = `}<span parentName="code" {...{
            "className": "token variable"
          }}>{`$localSignaturePath`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`+`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"\\set_outlook_signature_timestamp.txt"`}</span>{`
`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`if`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`-not`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`Test-Path`}</span>{` `}<span parentName="code" {...{
            "className": "token variable"
          }}>{`$timestampPath`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
    `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`# If no timestamp is found, the script hasn't run`}</span>{`
    `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`# So lets set last run date to the very distant past`}</span>{`
    `}<span parentName="code" {...{
            "className": "token variable"
          }}>{`$epoch`}</span>{` = `}<span parentName="code" {...{
            "className": "token function"
          }}>{`Get-Date`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`-`}</span>{`Year 1970
    `}<span parentName="code" {...{
            "className": "token function"
          }}>{`New-Item`}</span>{` `}<span parentName="code" {...{
            "className": "token variable"
          }}>{`$timestampPath`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`-`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`type`}</span>{` file
    `}<span parentName="code" {...{
            "className": "token function"
          }}>{`Add-Content`}</span>{` `}<span parentName="code" {...{
            "className": "token variable"
          }}>{`$timestampPath`}</span>{` `}<span parentName="code" {...{
            "className": "token variable"
          }}>{`$epoch`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`ToFileTimeUtc`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`
`}<span parentName="code" {...{
            "className": "token variable"
          }}>{`$timestamp`}</span>{` = `}<span parentName="code" {...{
            "className": "token namespace"
          }}>{`[DateTime]`}</span>{`::FromFileTimeUtc`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`Get-Content`}</span>{` `}<span parentName="code" {...{
            "className": "token variable"
          }}>{`$timestampPath`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
`}<span parentName="code" {...{
            "className": "token variable"
          }}>{`$currentTime`}</span>{` = `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`Get-Date`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`ToUniversalTime`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`

`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`# Get time since the last run of this script`}</span>{`
`}<span parentName="code" {...{
            "className": "token variable"
          }}>{`$timeSinceLastRun`}</span>{` = `}<span parentName="code" {...{
            "className": "token variable"
          }}>{`$currentTime`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`Subtract`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token variable"
          }}>{`$timestamp`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`

`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`if`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token variable"
          }}>{`$timeSinceLastRun`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`TotalDays `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`-lt`}</span>{` `}<span parentName="code" {...{
            "className": "token variable"
          }}>{`$SIGNATURE_MAX_AGE`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
    `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`# Get time since last user modification`}</span>{`
    `}<span parentName="code" {...{
            "className": "token variable"
          }}>{`$userTimestamp`}</span>{` = `}<span parentName="code" {...{
            "className": "token variable"
          }}>{`$ADUser`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`whenChanged`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span>{`0`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span>{`
    `}<span parentName="code" {...{
            "className": "token variable"
          }}>{`$timeSinceLastUserChange`}</span>{` = `}<span parentName="code" {...{
            "className": "token variable"
          }}>{`$userTimestamp`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`Subtract`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token variable"
          }}>{`$timestamp`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`

    `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`# Get time since last template change`}</span>{`
    `}<span parentName="code" {...{
            "className": "token variable"
          }}>{`$composeTimestamp`}</span>{` = `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`Get-Item`}</span>{` `}<span parentName="code" {...{
            "className": "token variable"
          }}>{`$SIGNATURE_COMPOSE_TEMPLATE`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`LastWriteTimeUtc
    `}<span parentName="code" {...{
            "className": "token variable"
          }}>{`$replyTimestamp`}</span>{` = `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`Get-Item`}</span>{` `}<span parentName="code" {...{
            "className": "token variable"
          }}>{`$SIGNATURE_REPLY_TEMPLATE`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`LastWriteTimeUtc
    `}<span parentName="code" {...{
            "className": "token variable"
          }}>{`$timeSinceLastComposeChange`}</span>{` = `}<span parentName="code" {...{
            "className": "token variable"
          }}>{`$composeTimestamp`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`Subtract`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token variable"
          }}>{`$timestamp`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
    `}<span parentName="code" {...{
            "className": "token variable"
          }}>{`$timeSinceLastReplyChange`}</span>{` = `}<span parentName="code" {...{
            "className": "token variable"
          }}>{`$replyTimestamp`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`Subtract`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token variable"
          }}>{`$timestamp`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`

    `}<span parentName="code" {...{
            "className": "token variable"
          }}>{`$changeDetected`}</span>{` = `}<span parentName="code" {...{
            "className": "token boolean"
          }}>{`$false`}</span>{`

    `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`# Positive seconds means the timestamp is older`}</span>{`
    `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`if`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token variable"
          }}>{`$timeSinceLastUserChange`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`TotalSeconds `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`-gt`}</span>{` 0`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
        `}<span parentName="code" {...{
            "className": "token function"
          }}>{`Write-Host`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"User has been modified, renewing signature"`}</span>{`
        `}<span parentName="code" {...{
            "className": "token variable"
          }}>{`$changeDetected`}</span>{` = `}<span parentName="code" {...{
            "className": "token boolean"
          }}>{`$true`}</span>{`
    `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`

    `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`if`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token variable"
          }}>{`$timeSinceLastComposeChange`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`TotalSeconds `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`-gt`}</span>{` 0`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
        `}<span parentName="code" {...{
            "className": "token function"
          }}>{`Write-Host`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"Compose template has been modified, renewing signature"`}</span>{`
        `}<span parentName="code" {...{
            "className": "token variable"
          }}>{`$changeDetected`}</span>{` = `}<span parentName="code" {...{
            "className": "token boolean"
          }}>{`$true`}</span>{`
    `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`

    `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`if`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token variable"
          }}>{`$timeSinceLastReplyChange`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`TotalSeconds `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`-gt`}</span>{` 0`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
        `}<span parentName="code" {...{
            "className": "token function"
          }}>{`Write-Host`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"Reply template has been modified, renewing signature"`}</span>{`
        `}<span parentName="code" {...{
            "className": "token variable"
          }}>{`$changeDetected`}</span>{` = `}<span parentName="code" {...{
            "className": "token boolean"
          }}>{`$true`}</span>{`
    `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`

    `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`if`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`-not`}</span>{` `}<span parentName="code" {...{
            "className": "token variable"
          }}>{`$changeDetected`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
        `}<span parentName="code" {...{
            "className": "token function"
          }}>{`Write-Host`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"No changes detected, exiting..."`}</span>{`
        `}<span parentName="code" {...{
            "className": "token function"
          }}>{`Stop-Transcript`}</span>{`
        `}<span parentName="code" {...{
            "className": "token function"
          }}>{`Copy-Item`}</span>{` `}<span parentName="code" {...{
            "className": "token variable"
          }}>{`$logPath`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`-`}</span>{`Destination `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token variable"
          }}>{`$LOG_FOLDER`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`+`}</span>{` `}<span parentName="code" {...{
            "className": "token variable"
          }}>{`$SIGNATURE_LOG_FOLDER`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`+`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"signature-"`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`+`}</span>{` `}<span parentName="code" {...{
            "className": "token variable"
          }}>{`$username`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`+`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`".log"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
        `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`exit`}</span>{`
    `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`else`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
    `}<span parentName="code" {...{
            "className": "token function"
          }}>{`Write-Host`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"Signature is old, lets renew it"`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`


`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`# Load word.application enums`}</span>{`
`}<span parentName="code" {...{
            "className": "token namespace"
          }}>{`[System.Reflection.Assembly]`}</span>{`::LoadWithPartialName`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"Microsoft.Office.Interop.Word"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`|`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`Out-Null`}</span>{`

`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`# Text manipulation settings`}</span>{`
`}<span parentName="code" {...{
            "className": "token variable"
          }}>{`$replaceAll`}</span>{` = `}<span parentName="code" {...{
            "className": "token namespace"
          }}>{`[Enum]`}</span>{`::Parse`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token namespace"
          }}>{`[Microsoft.Office.Interop.Word.WdReplace]`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"wdReplaceAll"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
`}<span parentName="code" {...{
            "className": "token variable"
          }}>{`$matchCase`}</span>{` = `}<span parentName="code" {...{
            "className": "token boolean"
          }}>{`$false`}</span>{`
`}<span parentName="code" {...{
            "className": "token variable"
          }}>{`$matchWholeWord`}</span>{` = `}<span parentName="code" {...{
            "className": "token boolean"
          }}>{`$true`}</span>{`
`}<span parentName="code" {...{
            "className": "token variable"
          }}>{`$matchWildcards`}</span>{` = `}<span parentName="code" {...{
            "className": "token boolean"
          }}>{`$false`}</span>{`
`}<span parentName="code" {...{
            "className": "token variable"
          }}>{`$matchSoundsLike`}</span>{` = `}<span parentName="code" {...{
            "className": "token boolean"
          }}>{`$false`}</span>{`
`}<span parentName="code" {...{
            "className": "token variable"
          }}>{`$matchAllWordForms`}</span>{` = `}<span parentName="code" {...{
            "className": "token boolean"
          }}>{`$false`}</span>{`
`}<span parentName="code" {...{
            "className": "token variable"
          }}>{`$forward`}</span>{` = `}<span parentName="code" {...{
            "className": "token boolean"
          }}>{`$true`}</span>{`
`}<span parentName="code" {...{
            "className": "token variable"
          }}>{`$wrap`}</span>{` = `}<span parentName="code" {...{
            "className": "token namespace"
          }}>{`[Enum]`}</span>{`::Parse`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token namespace"
          }}>{`[Microsoft.Office.Interop.Word.WdFindWrap]`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"wdFindContinue"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
`}<span parentName="code" {...{
            "className": "token variable"
          }}>{`$format`}</span>{` = `}<span parentName="code" {...{
            "className": "token boolean"
          }}>{`$true`}</span>{`

`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`# Create instance of Office Word`}</span>{`
`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`try`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
    `}<span parentName="code" {...{
            "className": "token variable"
          }}>{`$MSWord`}</span>{` = `}<span parentName="code" {...{
            "className": "token function"
          }}>{`New-Object`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`-`}</span>{`ComObject word`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`application
    `}<span parentName="code" {...{
            "className": "token function"
          }}>{`Write-Host`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"Word version is: "`}</span>{` `}<span parentName="code" {...{
            "className": "token variable"
          }}>{`$MSWord`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`BuildFull
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`catch`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
    `}<span parentName="code" {...{
            "className": "token function"
          }}>{`Write-Host`}</span>{` `}<span parentName="code" {...{
            "className": "token variable"
          }}>{`$_`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`Exception`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`Message
    `}<span parentName="code" {...{
            "className": "token function"
          }}>{`Write-Host`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"Could not load Word, checking for office install"`}</span>{`

    `}<span parentName="code" {...{
            "className": "token variable"
          }}>{`$comObject`}</span>{` = `}<span parentName="code" {...{
            "className": "token function"
          }}>{`Get-ChildItem`}</span>{` HKLM:\\Software\\Classes `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`-`}</span>{`ea 0 `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`|`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`Where-Object`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{` `}<span parentName="code" {...{
            "className": "token variable"
          }}>{`$_`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`PSChildName `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`-eq`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'Word.Application'`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`-and`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`Get-ItemProperty`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"`}<span parentName="span" {...{
              "className": "token function"
            }}>{`$`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`(`}</span>{`_`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`.`}</span>{`PSPath`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`)`}</span></span>{`\\CLSID"`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`-`}</span>{`ea 0`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`
    `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`if`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token variable"
          }}>{`$comObject`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`-ne`}</span>{` `}<span parentName="code" {...{
            "className": "token variable"
          }}>{`$null`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
        `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`if`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`Test-Path`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"C:\\program files\\Microsoft Office 15\\ClientX86"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
            `}<span parentName="code" {...{
            "className": "token function"
          }}>{`Write-Host`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"Repairing Office 32 bit"`}</span>{`
            `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`# CMD Prompt style command for background office repair`}</span>{`
            & `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"C:\\program files\\Microsoft Office 15\\ClientX86\\OfficeClickToRun.exe"`}</span>{` scenario=Repair DisplayLevel=False
        `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`elseif`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`Test-Path`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"C:\\program files\\Microsoft Office 15\\ClientX64"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
            `}<span parentName="code" {...{
            "className": "token function"
          }}>{`Write-Host`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"Repairing Office 64 bit"`}</span>{`
            `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`# CMD Prompt style command for background office repair`}</span>{`
            & `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"C:\\program files\\Microsoft Office 15\\ClientX64\\OfficeClickToRun.exe"`}</span>{` scenario=Repair DisplayLevel=False
        `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`else`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
            `}<span parentName="code" {...{
            "className": "token function"
          }}>{`Write-Host`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"OfficeClickToRun not found, repair failed"`}</span>{`
        `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`
    `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`else`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
        `}<span parentName="code" {...{
            "className": "token function"
          }}>{`Write-Host`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"Word COM object not found, please check office is installed"`}</span>{`
    `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`

    `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`# Finish transcription to log file`}</span>{`
    `}<span parentName="code" {...{
            "className": "token function"
          }}>{`Stop-Transcript`}</span>{`

    `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`# Send log file to Logs folder`}</span>{`
    `}<span parentName="code" {...{
            "className": "token function"
          }}>{`Copy-Item`}</span>{` `}<span parentName="code" {...{
            "className": "token variable"
          }}>{`$logPath`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`-`}</span>{`Destination `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token variable"
          }}>{`$LOG_FOLDER`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`+`}</span>{` `}<span parentName="code" {...{
            "className": "token variable"
          }}>{`$SIGNATURE_LOG_FOLDER`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`+`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"signature-"`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`+`}</span>{` `}<span parentName="code" {...{
            "className": "token variable"
          }}>{`$username`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`+`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`".log"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`

    `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`exit`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`

`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`# Check for pass by reference or not`}</span>{`
`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`# This is a disgusting hack, thanks Microsoft...`}</span>{`
`}<span parentName="code" {...{
            "className": "token variable"
          }}>{`$isPassByRef`}</span>{` = `}<span parentName="code" {...{
            "className": "token boolean"
          }}>{`$true`}</span>{`
`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`try`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
    `}<span parentName="code" {...{
            "className": "token variable"
          }}>{`$MSWord`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`Repeat`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token namespace"
          }}>{`[ref]`}</span>{`0`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`catch`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
    `}<span parentName="code" {...{
            "className": "token variable"
          }}>{`$isPassByRef`}</span>{` = `}<span parentName="code" {...{
            "className": "token boolean"
          }}>{`$false`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`
`}<span parentName="code" {...{
            "className": "token function"
          }}>{`Write-Host`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"Pass by ref: "`}</span>{` `}<span parentName="code" {...{
            "className": "token variable"
          }}>{`$isPassByRef`}</span>{`


`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`function`}</span>{` ReplaceText`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token variable"
          }}>{`$find`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token variable"
          }}>{`$replace`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
    `}<span parentName="code" {...{
            "className": "token variable"
          }}>{`$currentDocument`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`Select`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
    `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`try`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
        `}<span parentName="code" {...{
            "className": "token variable"
          }}>{`$isMatched`}</span>{` = `}<span parentName="code" {...{
            "className": "token variable"
          }}>{`$MSWord`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`Selection`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`Find`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`Execute`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`
            `}<span parentName="code" {...{
            "className": "token variable"
          }}>{`$find`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
            `}<span parentName="code" {...{
            "className": "token variable"
          }}>{`$matchCase`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
            `}<span parentName="code" {...{
            "className": "token variable"
          }}>{`$matchWholeWord`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
            `}<span parentName="code" {...{
            "className": "token variable"
          }}>{`$matchWildcards`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
            `}<span parentName="code" {...{
            "className": "token variable"
          }}>{`$matchSoundsLike`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
            `}<span parentName="code" {...{
            "className": "token variable"
          }}>{`$matchAllWordForms`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
            `}<span parentName="code" {...{
            "className": "token variable"
          }}>{`$forward`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
            `}<span parentName="code" {...{
            "className": "token variable"
          }}>{`$wrap`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
            `}<span parentName="code" {...{
            "className": "token variable"
          }}>{`$format`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
            `}<span parentName="code" {...{
            "className": "token variable"
          }}>{`$replace`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
            `}<span parentName="code" {...{
            "className": "token variable"
          }}>{`$replaceAll`}</span>{`
        `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
        `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`if`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token variable"
          }}>{`$isMatched`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
            `}<span parentName="code" {...{
            "className": "token function"
          }}>{`Write-Host`}</span>{` `}<span parentName="code" {...{
            "className": "token variable"
          }}>{`$find`}</span>{` `}<span parentName="code" {...{
            "className": "token variable"
          }}>{`$replace`}</span>{`
            `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`return`}</span>{` `}<span parentName="code" {...{
            "className": "token boolean"
          }}>{`$true`}</span>{`
        `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`
    `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`catch`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
        `}<span parentName="code" {...{
            "className": "token function"
          }}>{`Write-Host`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"Replace failed for:"`}</span>{` `}<span parentName="code" {...{
            "className": "token variable"
          }}>{`$find`}</span>{`
    `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`
    `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`return`}</span>{` `}<span parentName="code" {...{
            "className": "token boolean"
          }}>{`$false`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`

`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`function`}</span>{` FindText`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token variable"
          }}>{`$find`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
    `}<span parentName="code" {...{
            "className": "token variable"
          }}>{`$currentDocument`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`Select`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
    `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`if`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token variable"
          }}>{`$MSWord`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`Selection`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`Find`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`Execute`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`
        `}<span parentName="code" {...{
            "className": "token variable"
          }}>{`$find`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
        `}<span parentName="code" {...{
            "className": "token variable"
          }}>{`$matchCase`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
        `}<span parentName="code" {...{
            "className": "token variable"
          }}>{`$matchWholeWord`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
        `}<span parentName="code" {...{
            "className": "token variable"
          }}>{`$matchWildcards`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
        `}<span parentName="code" {...{
            "className": "token variable"
          }}>{`$matchSoundsLike`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
        `}<span parentName="code" {...{
            "className": "token variable"
          }}>{`$matchAllWordForms`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
        `}<span parentName="code" {...{
            "className": "token variable"
          }}>{`$forward`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
        `}<span parentName="code" {...{
            "className": "token variable"
          }}>{`$wrap`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
        `}<span parentName="code" {...{
            "className": "token variable"
          }}>{`$format`}</span>{`
    `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
        `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`return`}</span>{` `}<span parentName="code" {...{
            "className": "token boolean"
          }}>{`$true`}</span>{`
    `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`
    `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`return`}</span>{` `}<span parentName="code" {...{
            "className": "token boolean"
          }}>{`$false`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`

`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`function`}</span>{` RemoveText`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token variable"
          }}>{`$text`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
    ReplaceText `}<span parentName="code" {...{
            "className": "token variable"
          }}>{`$text`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`""`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`

`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`function`}</span>{` SetSignature`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token variable"
          }}>{`$aTemplateFile`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token variable"
          }}>{`$aTemplateName`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`

    `}<span parentName="code" {...{
            "className": "token function"
          }}>{`Write-Host`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"Copying Signature file: `}<span parentName="span" {...{
              "className": "token variable"
            }}>{`$aTemplateFile`}</span>{`"`}</span>{`
    `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`try`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
        `}<span parentName="code" {...{
            "className": "token function"
          }}>{`Copy-Item`}</span>{` `}<span parentName="code" {...{
            "className": "token variable"
          }}>{`$aTemplateFile`}</span>{` `}<span parentName="code" {...{
            "className": "token variable"
          }}>{`$localSignaturePath`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`-`}</span>{`Recurse `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`-`}</span>{`Force
    `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`catch`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
        `}<span parentName="code" {...{
            "className": "token function"
          }}>{`Write-Host`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"Couldn't load template, error: "`}</span>{` `}<span parentName="code" {...{
            "className": "token variable"
          }}>{`$_`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`Exception`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`Message
        `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`return`}</span>{` `}<span parentName="code" {...{
            "className": "token boolean"
          }}>{`$false`}</span>{`
    `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`

    `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`# Insert variables from Active Directory into file`}</span>{`
    `}<span parentName="code" {...{
            "className": "token variable"
          }}>{`$fullPath`}</span>{` = `}<span parentName="code" {...{
            "className": "token variable"
          }}>{`$localSignaturePath`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`+`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"\\"`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`+`}</span>{` `}<span parentName="code" {...{
            "className": "token variable"
          }}>{`$aTemplateFile`}</span>{`
    `}<span parentName="code" {...{
            "className": "token variable"
          }}>{`$currentDocument`}</span>{` = `}<span parentName="code" {...{
            "className": "token variable"
          }}>{`$MSWord`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`Documents`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`Open`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token variable"
          }}>{`$fullPath`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token boolean"
          }}>{`$false`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token boolean"
          }}>{`$false`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token boolean"
          }}>{`$false`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`#filename, show convert file, open read-only, add to recent files`}</span>{`

    `}<span parentName="code" {...{
            "className": "token function"
          }}>{`Write-Host`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"Setting signature values for `}<span parentName="span" {...{
              "className": "token variable"
            }}>{`$aTemplateName`}</span>{`"`}</span>{`
    `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`foreach`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token variable"
          }}>{`$property`}</span>{` in `}<span parentName="code" {...{
            "className": "token variable"
          }}>{`$ADUser`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`psobject`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`properties`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
        `}<span parentName="code" {...{
            "className": "token variable"
          }}>{`$propertyName`}</span>{` = `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"["`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`+`}</span>{` `}<span parentName="code" {...{
            "className": "token variable"
          }}>{`$property`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`name `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`+`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"]"`}</span>{`
        `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`if`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token variable"
          }}>{`$property`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`value `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`-ne`}</span>{` `}<span parentName="code" {...{
            "className": "token variable"
          }}>{`$null`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
            ReplaceText `}<span parentName="code" {...{
            "className": "token variable"
          }}>{`$propertyName`}</span>{` `}<span parentName="code" {...{
            "className": "token variable"
          }}>{`$property`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`value`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`toString`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` > `}<span parentName="code" {...{
            "className": "token variable"
          }}>{`$null`}</span>{`
        `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`
    `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`


    `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`# Set mail hyperlink`}</span>{`
    `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`foreach`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token variable"
          }}>{`$hyperlink`}</span>{` in `}<span parentName="code" {...{
            "className": "token variable"
          }}>{`$MSWord`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`ActiveDocument`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`Hyperlinks`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
        `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`if`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token variable"
          }}>{`$hyperlink`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`Name `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`-eq`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"mailto:[mail]"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
            `}<span parentName="code" {...{
            "className": "token variable"
          }}>{`$mailtoLink`}</span>{` = `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"mailto:"`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`+`}</span>{` `}<span parentName="code" {...{
            "className": "token variable"
          }}>{`$ADUser`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`mail
            `}<span parentName="code" {...{
            "className": "token variable"
          }}>{`$hyperlink`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`Address = `}<span parentName="code" {...{
            "className": "token variable"
          }}>{`$mailtoLink`}</span>{`
        `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`
    `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`

    `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`# Remove empty lines if necessary`}</span>{`
    `}<span parentName="code" {...{
            "className": "token function"
          }}>{`Write-Host`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"Removing empty template lines"`}</span>{`
    `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`foreach`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token variable"
          }}>{`$line`}</span>{` in `}<span parentName="code" {...{
            "className": "token variable"
          }}>{`$SIGNATURE_TEMPLATE_BLANKS`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
        RemoveText `}<span parentName="code" {...{
            "className": "token variable"
          }}>{`$line`}</span>{` > `}<span parentName="code" {...{
            "className": "token variable"
          }}>{`$null`}</span>{`
    `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`

    `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`# If any blanks failed to be removed, don't save`}</span>{`
    `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`if`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`FindText `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"["`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
        `}<span parentName="code" {...{
            "className": "token function"
          }}>{`Write-Host`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"Blank template lines found, saving failed, Please check Powershell-Vars.ps1 string removal array"`}</span>{`

        `}<span parentName="code" {...{
            "className": "token variable"
          }}>{`$currentDocument`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`Close`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
        `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`return`}</span>{` `}<span parentName="code" {...{
            "className": "token boolean"
          }}>{`$false`}</span>{`
    `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`

    `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`# Save new message signature`}</span>{`
    `}<span parentName="code" {...{
            "className": "token function"
          }}>{`Write-Host`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"Saving signature `}<span parentName="span" {...{
              "className": "token variable"
            }}>{`$aTemplateName`}</span>{`"`}</span>{`

    `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`# Save HTML`}</span>{`
    `}<span parentName="code" {...{
            "className": "token variable"
          }}>{`$saveFormat`}</span>{` = `}<span parentName="code" {...{
            "className": "token namespace"
          }}>{`[Enum]`}</span>{`::Parse`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token namespace"
          }}>{`[Microsoft.Office.Interop.Word.WdSaveFormat]`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"wdFormatHTML"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
    `}<span parentName="code" {...{
            "className": "token variable"
          }}>{`$path`}</span>{` = `}<span parentName="code" {...{
            "className": "token variable"
          }}>{`$localSignaturePath`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`+`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"\\"`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`+`}</span>{` `}<span parentName="code" {...{
            "className": "token variable"
          }}>{`$aTemplateName`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`+`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`".htm"`}</span>{`
    `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`try`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
        `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`#filename, file format, lock comments, password, add to recent files`}</span>{`
        `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`if`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token variable"
          }}>{`$isPassByRef`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
            `}<span parentName="code" {...{
            "className": "token variable"
          }}>{`$currentDocument`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`SaveAs`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token namespace"
          }}>{`[ref]`}</span><span parentName="code" {...{
            "className": "token variable"
          }}>{`$path`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token namespace"
          }}>{`[ref]`}</span><span parentName="code" {...{
            "className": "token variable"
          }}>{`$saveFormat`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token namespace"
          }}>{`[ref]`}</span><span parentName="code" {...{
            "className": "token boolean"
          }}>{`$false`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token namespace"
          }}>{`[ref]`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`""`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token namespace"
          }}>{`[ref]`}</span><span parentName="code" {...{
            "className": "token boolean"
          }}>{`$false`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
        `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`else`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
            `}<span parentName="code" {...{
            "className": "token variable"
          }}>{`$currentDocument`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`SaveAs`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token variable"
          }}>{`$path`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token variable"
          }}>{`$saveFormat`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token boolean"
          }}>{`$false`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`""`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token boolean"
          }}>{`$false`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
        `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`
    `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`catch`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
        `}<span parentName="code" {...{
            "className": "token function"
          }}>{`Write-Host`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"Failed to save as HTML, error: "`}</span>{` `}<span parentName="code" {...{
            "className": "token variable"
          }}>{`$_`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`Exception`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`Message
        `}<span parentName="code" {...{
            "className": "token variable"
          }}>{`$currentDocument`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`Close`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
        `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`return`}</span>{` `}<span parentName="code" {...{
            "className": "token boolean"
          }}>{`$false`}</span>{`
    `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`

    `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`# Save RTF`}</span>{`
    `}<span parentName="code" {...{
            "className": "token variable"
          }}>{`$saveFormat`}</span>{` = `}<span parentName="code" {...{
            "className": "token namespace"
          }}>{`[Enum]`}</span>{`::Parse`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token namespace"
          }}>{`[Microsoft.Office.Interop.Word.WdSaveFormat]`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"wdFormatRTF"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
    `}<span parentName="code" {...{
            "className": "token variable"
          }}>{`$path`}</span>{` = `}<span parentName="code" {...{
            "className": "token variable"
          }}>{`$localSignaturePath`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`+`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"\\"`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`+`}</span>{` `}<span parentName="code" {...{
            "className": "token variable"
          }}>{`$aTemplateName`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`+`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`".rtf"`}</span>{`
    `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`try`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
        `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`if`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token variable"
          }}>{`$isPassByRef`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
            `}<span parentName="code" {...{
            "className": "token variable"
          }}>{`$currentDocument`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`SaveAs`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token namespace"
          }}>{`[ref]`}</span><span parentName="code" {...{
            "className": "token variable"
          }}>{`$path`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token namespace"
          }}>{`[ref]`}</span><span parentName="code" {...{
            "className": "token variable"
          }}>{`$saveFormat`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token namespace"
          }}>{`[ref]`}</span><span parentName="code" {...{
            "className": "token boolean"
          }}>{`$false`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token namespace"
          }}>{`[ref]`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`""`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token namespace"
          }}>{`[ref]`}</span><span parentName="code" {...{
            "className": "token boolean"
          }}>{`$false`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
        `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`else`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
            `}<span parentName="code" {...{
            "className": "token variable"
          }}>{`$currentDocument`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`SaveAs`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token variable"
          }}>{`$path`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token variable"
          }}>{`$saveFormat`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token boolean"
          }}>{`$false`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`""`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token boolean"
          }}>{`$false`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
        `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`
    `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`catch`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
        `}<span parentName="code" {...{
            "className": "token function"
          }}>{`Write-Host`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"Failed to save as RTF, error: "`}</span>{` `}<span parentName="code" {...{
            "className": "token variable"
          }}>{`$_`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`Exception`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`Message
        `}<span parentName="code" {...{
            "className": "token variable"
          }}>{`$currentDocument`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`Close`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
        `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`return`}</span>{` `}<span parentName="code" {...{
            "className": "token boolean"
          }}>{`$false`}</span>{`
    `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`

    `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`# Save TXT`}</span>{`
    `}<span parentName="code" {...{
            "className": "token variable"
          }}>{`$saveFormat`}</span>{` = `}<span parentName="code" {...{
            "className": "token namespace"
          }}>{`[Enum]`}</span>{`::Parse`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token namespace"
          }}>{`[Microsoft.Office.Interop.Word.WdSaveFormat]`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"wdFormatText"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
    `}<span parentName="code" {...{
            "className": "token variable"
          }}>{`$path`}</span>{` = `}<span parentName="code" {...{
            "className": "token variable"
          }}>{`$localSignaturePath`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`+`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"\\"`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`+`}</span>{` `}<span parentName="code" {...{
            "className": "token variable"
          }}>{`$aTemplateName`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`+`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`".txt"`}</span>{`
    `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`try`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
        `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`if`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token variable"
          }}>{`$isPassByRef`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
            `}<span parentName="code" {...{
            "className": "token variable"
          }}>{`$currentDocument`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`SaveAs`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token namespace"
          }}>{`[ref]`}</span><span parentName="code" {...{
            "className": "token variable"
          }}>{`$path`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token namespace"
          }}>{`[ref]`}</span><span parentName="code" {...{
            "className": "token variable"
          }}>{`$saveFormat`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token namespace"
          }}>{`[ref]`}</span><span parentName="code" {...{
            "className": "token boolean"
          }}>{`$false`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token namespace"
          }}>{`[ref]`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`""`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token namespace"
          }}>{`[ref]`}</span><span parentName="code" {...{
            "className": "token boolean"
          }}>{`$false`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
        `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`else`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
            `}<span parentName="code" {...{
            "className": "token variable"
          }}>{`$currentDocument`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`SaveAs`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token variable"
          }}>{`$path`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token variable"
          }}>{`$saveFormat`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token boolean"
          }}>{`$false`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`""`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token boolean"
          }}>{`$false`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
        `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`
    `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`catch`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
        `}<span parentName="code" {...{
            "className": "token function"
          }}>{`Write-Host`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"Failed to save as TXT, error: "`}</span>{` `}<span parentName="code" {...{
            "className": "token variable"
          }}>{`$_`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`Exception`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`Message
        `}<span parentName="code" {...{
            "className": "token variable"
          }}>{`$currentDocument`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`Close`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
        `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`return`}</span>{` `}<span parentName="code" {...{
            "className": "token boolean"
          }}>{`$false`}</span>{`
    `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`

    `}<span parentName="code" {...{
            "className": "token variable"
          }}>{`$currentDocument`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`Close`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
    `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`return`}</span>{` `}<span parentName="code" {...{
            "className": "token boolean"
          }}>{`$true`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`

`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`function`}</span>{` ExitGracefully`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
    `}<span parentName="code" {...{
            "className": "token variable"
          }}>{`$saveOptions`}</span>{` = `}<span parentName="code" {...{
            "className": "token namespace"
          }}>{`[Enum]`}</span>{`::Parse`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token namespace"
          }}>{`[Microsoft.Office.Interop.Word.WdSaveOptions]`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`  `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"wdDoNotSaveChanges"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
    `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`if`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token variable"
          }}>{`$isPassByRef`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
        `}<span parentName="code" {...{
            "className": "token variable"
          }}>{`$MSWord`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`Quit`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token namespace"
          }}>{`[ref]`}</span><span parentName="code" {...{
            "className": "token variable"
          }}>{`$saveOptions`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
    `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`else`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
        `}<span parentName="code" {...{
            "className": "token variable"
          }}>{`$MSWord`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`Quit`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token variable"
          }}>{`$saveOptions`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
    `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`

    `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`# Finish transcription to log file`}</span>{`
    `}<span parentName="code" {...{
            "className": "token function"
          }}>{`Stop-Transcript`}</span>{`

    `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`# Send log file to Logs folder`}</span>{`
    `}<span parentName="code" {...{
            "className": "token function"
          }}>{`Copy-Item`}</span>{` `}<span parentName="code" {...{
            "className": "token variable"
          }}>{`$logPath`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`-`}</span>{`Destination `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token variable"
          }}>{`$LOG_FOLDER`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`+`}</span>{` `}<span parentName="code" {...{
            "className": "token variable"
          }}>{`$SIGNATURE_LOG_FOLDER`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`+`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"signature-"`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`+`}</span>{` `}<span parentName="code" {...{
            "className": "token variable"
          }}>{`$username`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`+`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`".log"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`

`}<span parentName="code" {...{
            "className": "token function"
          }}>{`Write-Host`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"Waiting 2 seconds for AD object"`}</span>{`
`}<span parentName="code" {...{
            "className": "token function"
          }}>{`Start-Sleep`}</span>{` 2

`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`# Run signature filler`}</span>{`
`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`if`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`-not`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`SetSignature `}<span parentName="code" {...{
            "className": "token variable"
          }}>{`$SIGNATURE_COMPOSE_TEMPLATE`}</span>{` `}<span parentName="code" {...{
            "className": "token variable"
          }}>{`$SIGNATURE_COMPOSE_NAME`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
    `}<span parentName="code" {...{
            "className": "token function"
          }}>{`Write-Host`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"Compose Template creation failed, exiting"`}</span>{`
    ExitGracefully
    `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`exit`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`
`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`if`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token variable"
          }}>{`$SIGNATURE_SEPARATE`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
    `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`if`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`-not`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`SetSignature `}<span parentName="code" {...{
            "className": "token variable"
          }}>{`$SIGNATURE_REPLY_TEMPLATE`}</span>{` `}<span parentName="code" {...{
            "className": "token variable"
          }}>{`$SIGNATURE_REPLY_NAME`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
        `}<span parentName="code" {...{
            "className": "token function"
          }}>{`Write-Host`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"Reply Template creation failed, exiting"`}</span>{`
        ExitGracefully
        `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`exit`}</span>{`
    `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`

`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`# All Office versions`}</span>{`
`}<span parentName="code" {...{
            "className": "token function"
          }}>{`Write-Host`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"Adding signature to Office"`}</span>{`
`}<span parentName="code" {...{
            "className": "token variable"
          }}>{`$emailOptions`}</span>{` = `}<span parentName="code" {...{
            "className": "token variable"
          }}>{`$MSWord`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`EmailOptions
`}<span parentName="code" {...{
            "className": "token variable"
          }}>{`$emailSignature`}</span>{` = `}<span parentName="code" {...{
            "className": "token variable"
          }}>{`$emailOptions`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`EmailSignature
`}<span parentName="code" {...{
            "className": "token variable"
          }}>{`$emailCompose`}</span>{` = `}<span parentName="code" {...{
            "className": "token variable"
          }}>{`$emailOptions`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`ComposeStyle
`}<span parentName="code" {...{
            "className": "token variable"
          }}>{`$emailReply`}</span>{` = `}<span parentName="code" {...{
            "className": "token variable"
          }}>{`$emailOptions`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`ReplyStyle

`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`try`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
    `}<span parentName="code" {...{
            "className": "token variable"
          }}>{`$emailSignature`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`NewMessageSignature = `}<span parentName="code" {...{
            "className": "token variable"
          }}>{`$SIGNATURE_COMPOSE_NAME`}</span>{`
    `}<span parentName="code" {...{
            "className": "token variable"
          }}>{`$emailSignature`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`ReplyMessageSignature = `}<span parentName="code" {...{
            "className": "token variable"
          }}>{`$SIGNATURE_REPLY_NAME`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`catch`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
    `}<span parentName="code" {...{
            "className": "token function"
          }}>{`Write-Host`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"Setting default signature failed, exiting"`}</span>{`
    `}<span parentName="code" {...{
            "className": "token function"
          }}>{`Write-Host`}</span>{` `}<span parentName="code" {...{
            "className": "token variable"
          }}>{`$_`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`Exception`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`Message
    ExitGracefully
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`


`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`if`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token variable"
          }}>{`$SIGNATURE_FORCE`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
    `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`# Office 2010`}</span>{`
    `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`If`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`Test-Path`}</span>{` HKCU:Software\\Microsoft\\Office\\14`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`0`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
        `}<span parentName="code" {...{
            "className": "token function"
          }}>{`Write-Host`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"Forcing set signature for Outlook 2010"`}</span>{`
        `}<span parentName="code" {...{
            "className": "token function"
          }}>{`New-ItemProperty`}</span>{` HKCU:`}<span parentName="code" {...{
            "className": "token string"
          }}>{`'\\Software\\Microsoft\\Office\\14.0\\Common\\MailSettings'`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`-`}</span>{`Name `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"ReplySignature"`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`-`}</span>{`Value `}<span parentName="code" {...{
            "className": "token variable"
          }}>{`$SIGNATURE_REPLY_NAME`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`-`}</span>{`PropertyType `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"String"`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`-`}</span>{`Force
        `}<span parentName="code" {...{
            "className": "token function"
          }}>{`New-ItemProperty`}</span>{` HKCU:`}<span parentName="code" {...{
            "className": "token string"
          }}>{`'\\Software\\Microsoft\\Office\\14.0\\Common\\MailSettings'`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`-`}</span>{`Name `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"NewSignature"`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`-`}</span>{`Value `}<span parentName="code" {...{
            "className": "token variable"
          }}>{`$SIGNATURE_COMPOSE_NAME`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`-`}</span>{`PropertyType `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"String"`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`-`}</span>{`Force
    `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`else`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
        `}<span parentName="code" {...{
            "className": "token function"
          }}>{`Write-Host`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"Office 2010 not installed, 2010 signatures skipped"`}</span>{`
    `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`

    `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`# Office 2013`}</span>{`
    `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`if`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`Test-Path`}</span>{` HKCU:Software\\Microsoft\\Office\\15`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`0`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
        `}<span parentName="code" {...{
            "className": "token function"
          }}>{`Write-Host`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"Forcing set signature for Outlook 2013"`}</span>{`
        `}<span parentName="code" {...{
            "className": "token function"
          }}>{`New-ItemProperty`}</span>{` HKCU:`}<span parentName="code" {...{
            "className": "token string"
          }}>{`'\\Software\\Microsoft\\Office\\15.0\\Common\\MailSettings'`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`-`}</span>{`Name `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"ReplySignature"`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`-`}</span>{`Value `}<span parentName="code" {...{
            "className": "token variable"
          }}>{`$SIGNATURE_REPLY_NAME`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`-`}</span>{`PropertyType `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"String"`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`-`}</span>{`Force
        `}<span parentName="code" {...{
            "className": "token function"
          }}>{`New-ItemProperty`}</span>{` HKCU:`}<span parentName="code" {...{
            "className": "token string"
          }}>{`'\\Software\\Microsoft\\Office\\15.0\\Common\\MailSettings'`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`-`}</span>{`Name `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"NewSignature"`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`-`}</span>{`Value `}<span parentName="code" {...{
            "className": "token variable"
          }}>{`$SIGNATURE_COMPOSE_NAME`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`-`}</span>{`PropertyType `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"String"`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`-`}</span>{`Force
    `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`else`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
        `}<span parentName="code" {...{
            "className": "token function"
          }}>{`Write-Host`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"Office 2013 not installed, 2013 signatures skipped"`}</span>{`
    `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`else`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
    `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`# Office 2010`}</span>{`
    `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`If`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`Test-RegistryValue`}</span>{` HKCU:`}<span parentName="code" {...{
            "className": "token string"
          }}>{`'\\Software\\Microsoft\\Office\\14.0\\Common\\MailSettings'`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"NewSignature"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
        `}<span parentName="code" {...{
            "className": "token function"
          }}>{`Write-Host`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"Removing signature enforcement for Outlook 2010"`}</span>{`
        `}<span parentName="code" {...{
            "className": "token function"
          }}>{`Remove-ItemProperty`}</span>{` HKCU:`}<span parentName="code" {...{
            "className": "token string"
          }}>{`'\\Software\\Microsoft\\Office\\14.0\\Common\\MailSettings'`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`-`}</span>{`Name `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"ReplySignature"`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`-`}</span>{`Force
        `}<span parentName="code" {...{
            "className": "token function"
          }}>{`Remove-ItemProperty`}</span>{` HKCU:`}<span parentName="code" {...{
            "className": "token string"
          }}>{`'\\Software\\Microsoft\\Office\\14.0\\Common\\MailSettings'`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`-`}</span>{`Name `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"NewSignature"`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`-`}</span>{`Force
    `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`

    `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`# Office 2013`}</span>{`
    `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`if`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`Test-RegistryValue`}</span>{` HKCU:`}<span parentName="code" {...{
            "className": "token string"
          }}>{`'\\Software\\Microsoft\\Office\\15.0\\Common\\MailSettings'`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"NewSignature"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
        `}<span parentName="code" {...{
            "className": "token function"
          }}>{`Write-Host`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"Removing signature enforcement for Outlook 2013"`}</span>{`
        `}<span parentName="code" {...{
            "className": "token function"
          }}>{`Remove-ItemProperty`}</span>{` HKCU:`}<span parentName="code" {...{
            "className": "token string"
          }}>{`'\\Software\\Microsoft\\Office\\15.0\\Common\\MailSettings'`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`-`}</span>{`Name `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"ReplySignature"`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`-`}</span>{`Force
        `}<span parentName="code" {...{
            "className": "token function"
          }}>{`Remove-ItemProperty`}</span>{` HKCU:`}<span parentName="code" {...{
            "className": "token string"
          }}>{`'\\Software\\Microsoft\\Office\\15.0\\Common\\MailSettings'`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`-`}</span>{`Name `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"NewSignature"`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`-`}</span>{`Force
    `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`

`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`if`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token variable"
          }}>{`$SIGNATURE_FORCE_THEME`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
    `}<span parentName="code" {...{
            "className": "token function"
          }}>{`Write-Host`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"Themes are enforced, setting font for compose and reply"`}</span>{`

    `}<span parentName="code" {...{
            "className": "token variable"
          }}>{`$emailCompose`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`Font`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`Name = `}<span parentName="code" {...{
            "className": "token variable"
          }}>{`$SIGNATURE_COMPOSE_FONT`}</span>{`
    `}<span parentName="code" {...{
            "className": "token variable"
          }}>{`$emailCompose`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`Font`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`Size = `}<span parentName="code" {...{
            "className": "token variable"
          }}>{`$SIGNATURE_COMPOSE_SIZE`}</span>{`
    `}<span parentName="code" {...{
            "className": "token variable"
          }}>{`$emailCompose`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`Font`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`Color = `}<span parentName="code" {...{
            "className": "token variable"
          }}>{`$SIGNATURE_COMPOSE_COLOR`}</span>{`

    `}<span parentName="code" {...{
            "className": "token variable"
          }}>{`$emailReply`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`Font`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`Name = `}<span parentName="code" {...{
            "className": "token variable"
          }}>{`$SIGNATURE_REPLY_FONT`}</span>{`
    `}<span parentName="code" {...{
            "className": "token variable"
          }}>{`$emailReply`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`Font`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`Size = `}<span parentName="code" {...{
            "className": "token variable"
          }}>{`$SIGNATURE_REPLY_SIZE`}</span>{`
    `}<span parentName="code" {...{
            "className": "token variable"
          }}>{`$emailReply`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`Font`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`Color = `}<span parentName="code" {...{
            "className": "token variable"
          }}>{`$SIGNATURE_REPLY_COLOR`}</span>{`

    `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`# Ensure no custom themes are used`}</span>{`
    `}<span parentName="code" {...{
            "className": "token variable"
          }}>{`$emailOptions`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`ThemeName = `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"none"`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`

`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`if`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token variable"
          }}>{`$SIGNATURE_MARK_COMMENTS`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
    `}<span parentName="code" {...{
            "className": "token function"
          }}>{`Write-Host`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"Email comments are marked, setting mark as user initials"`}</span>{`

    `}<span parentName="code" {...{
            "className": "token variable"
          }}>{`$emailOptions`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`MarkCommentsWith = `}<span parentName="code" {...{
            "className": "token variable"
          }}>{`$MSWord`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`UserInitials
    `}<span parentName="code" {...{
            "className": "token variable"
          }}>{`$emailOptions`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`MarkComments = `}<span parentName="code" {...{
            "className": "token boolean"
          }}>{`$true`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`

`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`# Timestamp the last run time`}</span>{`
`}<span parentName="code" {...{
            "className": "token variable"
          }}>{`$newTime`}</span>{` = `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`Get-Date`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`ToFileTimeUtc`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
`}<span parentName="code" {...{
            "className": "token function"
          }}>{`New-Item`}</span>{` `}<span parentName="code" {...{
            "className": "token variable"
          }}>{`$timestampPath`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`-`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`type`}</span>{` file `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`-`}</span>{`force
`}<span parentName="code" {...{
            "className": "token function"
          }}>{`Add-Content`}</span>{` `}<span parentName="code" {...{
            "className": "token variable"
          }}>{`$timestampPath`}</span>{` `}<span parentName="code" {...{
            "className": "token variable"
          }}>{`$newTime`}</span>{`

ExitGracefully`}</code></pre></div>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      