import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`Repair Sharepoint apps after theme change`}</h2>
    <p>{`On SharePoint, if you change the theme of an App on the App Catalog, or change a theme of a site that contains an Apps, all the Apps in the App Catalog will fail catastrophically.`}</p>
    <p>{`This script is a quick fixme that requires zero configuration, just upload it to the SharePoint server and run it to repair the App Catalog entirely.`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "powershell"
    }}><pre parentName="div" {...{
        "className": "language-powershell"
      }}><code parentName="pre" {...{
          "className": "language-powershell"
        }}><span parentName="code" {...{
            "className": "token comment"
          }}>{`#!/usr/local/bin/pwsh`}</span>{`
`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`# Written by George Paton`}</span>{`
`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`## This little number automatically fixes that annoying app breaking bug to do with themes`}</span>{`
`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`## If an app is complaining about being unable to open a file, run me on the SP server`}</span>{`


`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`# Add the sharepoint snapin to the current session`}</span>{`
`}<span parentName="code" {...{
            "className": "token function"
          }}>{`Add-PSSnapin`}</span>{` Microsoft`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`Sharepoint`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`Powershell

`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`# Get the app prefix`}</span>{`
`}<span parentName="code" {...{
            "className": "token variable"
          }}>{`$prefix`}</span>{` = `}<span parentName="code" {...{
            "className": "token function"
          }}>{`Get-SPAppSiteSubscriptionName`}</span>{`
`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`# Use the app prefix to get all app urls`}</span>{`
`}<span parentName="code" {...{
            "className": "token variable"
          }}>{`$allappurls`}</span>{` = `}<span parentName="code" {...{
            "className": "token function"
          }}>{`Get-SPSite`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`|`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`Get-SPWeb`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`-`}</span>{`Limit All `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`|`}</span>{` where `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span><span parentName="code" {...{
            "className": "token variable"
          }}>{`$_`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`url `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`-like`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"http://`}<span parentName="span" {...{
              "className": "token variable"
            }}>{`$prefix`}</span>{`*"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`

`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`foreach`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token variable"
          }}>{`$app`}</span>{` in `}<span parentName="code" {...{
            "className": "token variable"
          }}>{`$allappurls`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
    `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`# Loop through all apps within the default site with the app prefix of $prefix`}</span>{`
	`}<span parentName="code" {...{
            "className": "token variable"
          }}>{`$name`}</span>{` = `}<span parentName="code" {...{
            "className": "token variable"
          }}>{`$app`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`name
	`}<span parentName="code" {...{
            "className": "token variable"
          }}>{`$app`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`CustomMasterUrl = `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"/`}<span parentName="span" {...{
              "className": "token variable"
            }}>{`$name`}</span>{`/_catalogs/masterpage/app.master"`}</span>{`
	`}<span parentName="code" {...{
            "className": "token variable"
          }}>{`$app`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`MasterUrl = `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"/`}<span parentName="span" {...{
              "className": "token variable"
            }}>{`$name`}</span>{`/_catalogs/masterpage/app.master"`}</span>{`
	`}<span parentName="code" {...{
            "className": "token variable"
          }}>{`$app`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`Update`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
    `}<span parentName="code" {...{
            "className": "token function"
          }}>{`echo`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"Updating masterpage for `}<span parentName="span" {...{
              "className": "token variable"
            }}>{`$app`}</span>{`"`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span></code></pre></div>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      